import React from 'react'

const About = () => (
  <>
    <h1>
        Edit About component or pages/about.js to include your information.
    </h1>
  </>
)

export default About
